<script lang="ts" setup>
// function scrollTo(className: string) {
//   if (!document) return
//   document.getElementsByClassName(className)[0].scrollIntoView({
//     behavior: 'smooth'
//   })
// }

type ScrollMenuItem = {
  label: string
  className: string
}

const props = withDefaults(defineProps<{
  scrollMenuItems: ScrollMenuItem[],
  showLogo?: boolean
}>(), {
  showLogo: true
})
</script>

<template lang="pug">
.scroll-menu.flex.gap-20
  .logo
    nuxt-link(to="/")
      img(src="/images/logo-white.svg" alt="zulassung.digital Logo" class="lg:h-8").h-6.w-auto

  .menu-items.items-center.text-slate-50.gap-8.text-base.font-semibold.hidden(class="lg:flex xl:gap-12")
    .menu-item(
      v-for="item in props.scrollMenuItems"
      :key="item.className"
      @click="scrollToClass(item.className)"
    ) {{ item.label }}
    .menu-item.text-sky-200
      a(:href="`mailto:${useRuntimeConfig().public.email}`").flex.gap-3.items-center
        include ../public/images/icons/mail.svg
        span {{ useRuntimeConfig().public.email }}
</template>

<style lang="sass" scoped>
.scroll-menu
  .menu-item
    cursor: pointer

  svg path
    @apply stroke-sky-200
</style>
